import '../css/track.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';
import { useState } from 'react';
import { createColorPickers, createStorePaletteButton, holdsNFT, toggleNoDistractionsMode } from '../helpers';


export default function Track2({address,isConnected,isDisconnected}) {
    
    let pieces, radius, fft, mapMouseX, mapMouseY, audio, toggleBtn, uploadBtn, uploadedAudio, uploadAnim;
    const [colorPalette,setColorPalette] = useState(["#0f0639", "#ff006a", "#ff4f00", "#00f9d9"]);
    let uploadLoading = false;


    const preload = (p5) => {
        audio = p5.loadSound("/audio/2.mp3");
    }

    const uploadedAudioPlay = (audioFile) => {

        uploadLoading = false;

        if (audio.isPlaying()) {
            audio.pause();
        }

        audio = audioFile;
        audio.loop();
    }


    const setup = (p5, canvasParentRef) => {
        uploadAnim = p5.select('#uploading-animation');
        console.log('trac2')
        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        toggleBtn = p5.createButton("Play / Pause");

        
        if(holdsNFT(4, address)){
            // color pickers
            createColorPickers(p5,colorPalette,colorChanged);
            
            // upload track
            uploadBtn = p5.createFileInput((file) => {
                uploadLoading = true;
                uploadedAudio = p5.loadSound(file.data, uploadedAudioPlay);
                }
            );
    
            uploadBtn.addClass("upload-btn");
            
            // store color palette
            createStorePaletteButton(p5,colorPalette);
        }

        toggleBtn.addClass("toggle-btn");
        
        toggleBtn.mousePressed(toggleAudio);


        fft = new window.p5.FFT();

        audio.loop();
        
        pieces = 4;
        radius = p5.windowHeight / 4;
    }

    const draw = (p5) => {

        if (uploadLoading) {
            uploadAnim.addClass('is-visible');
        } else {
            uploadAnim.removeClass('is-visible');
        }

        p5.background(colorPalette[0]);

        p5.noFill();

        fft.analyze();
        const bass = fft.getEnergy("bass");
        const treble = fft.getEnergy(100, 150);
        const mid = fft.getEnergy("mid");

        const mapbass = p5.map(bass, 0, 255, -100, 800);
        const scalebass = p5.map(bass, 0, 255, 0.5, 1.2);

        const mapMid = p5.map(mid, 0, 255, -radius / 4, radius * 4);
        const scaleMid = p5.map(mid, 0, 255, 1, 1.5);

        const mapTreble = p5.map(treble, 0, 255, -radius / 4, radius * 4);
        const scaleTreble = p5.map(treble, 0, 255, 1, 1.5);

        mapMouseX = p5.map(p5.mouseX, 0, p5.width, 2, 0.1);
        mapMouseY = p5.map(p5.mouseY, 0, p5.height, p5.windowHeight / 8, p5.windowHeight / 6);

        pieces = mapMouseX;
        radius = mapMouseY;

        const mapScaleX = p5.map(p5.mouseX, 0, p5.width, 1, 0);
        const mapScaleY = p5.map(p5.mouseY, 0, p5.height, 0, 1);


        p5.translate(p5.width / 2, p5.height / 2);

        for (let i = 0; i < pieces; i += 0.01) {

            p5.rotate(p5.TWO_PI / pieces);

            /*----------  BASS  ----------*/
            p5.push();
            p5.strokeWeight(1);
            p5.stroke(colorPalette[1]);
            p5.scale(scalebass);
            p5.rotate(p5.frameCount * -0.5);
            p5.line(mapbass, radius / 2, radius, radius);
            p5.line(-mapbass, -radius / 2, radius, radius);
            p5.pop();


            /*----------  MID  ----------*/
            p5.push();
            p5.strokeWeight(1);
            p5.stroke(colorPalette[2]);
            p5.line(mapMid, radius, radius * 2, radius * 2);
            p5.pop();


            /*----------  TREMBLE  ----------*/
            p5.push();
            p5.stroke(colorPalette[3]);
            p5.scale(scaleTreble);
            p5.line(mapTreble, radius / 2, radius, radius);
            p5.pop();

        }

    }

    const colorChanged = (index,color) => {
        let temp = colorPalette;
        temp[index] = color;
        setColorPalette(temp);
    }

    const toggleAudio = () => {
        if (audio.isPlaying()) {
            audio.pause();
        } else {
            audio.play();
        }
    }


    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    useEffect(() => {
         document.addEventListener('keydown', toggleNoDistractionsMode);

        return () => {
            document.removeEventListener('keydown', toggleNoDistractionsMode);
            if(audio !== undefined)audio.disconnect()
        }
    }, [])

    return (
		<div className="content main">
            <div className="canvas-wrapper">
                <div id="p5_loading" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <div id="uploading-animation" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                
                <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
            </div>
        </div>
    );
}