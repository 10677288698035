import AVAX from "./AVAX";

export default function About() {

    return (
        <div className="container about">
            <h1 className="content__title">Synesthesia</h1>
            <h3>a sound interactive<br></br>digital art installation</h3> 
            
            <p>Each piece is the result of a dialog where Lprofessor prompts with music and 0xAcid paints with code - or vice versa! </p>

            <p>your mouse movement → alters the shapes or colors of the animated visualization.</p>

            
            <p>There are a total of <strong>16 tracks</strong> in the collection, each with a supply of 32 pieces</p>
            <ul>
                <li>Total supply: <strong>512</strong> <small>(16 x 32)</small></li>
                <li>1<span className="super">st</span> batch: 256 <small>(8 tracks x32)</small> - <strong>MINTING JUNE 18</strong> </li>
                <li>2<span className="super">nd</span> batch: 256 <small>(8 tracks x32)</small></li>
                <li>&nbsp;</li>
                <li>Public Sale: 3 <AVAX/></li>
                <li>Royalties: 4%</li>
            </ul>
            <p><i>Team Mint: 2 pieces for each track preminted</i></p>
            <br></br>
            <h2>Holders</h2>
            <p>Owning a track NFT unlocks the following perks:</p>
            <ul>
                <li>upload & visualize any audio track you like</li>
                <li>change the color palette of any piece you own</li>
                <li>persist it on the blockchain! next owner will have to pay 0.2 <AVAX/> fee to change it back</li>
            </ul>
            
            <br></br>
            <h2>Team</h2>
            <section className="team-section">
                <div className="team-member">
                    <div><img src="/img/acid.gif"></img></div>
                    <p><a href="https://twitter.com/gfk_acid" target="_blank">0xAcid</a> is a <span style={{textDecoration: 'line-through'}}>web developer</span> fully converted crypto cultist. Exploring art through code is his indoors creative escape when not working or studying protocols. Composability maxi, wants to put on-chain the whole enchilada; will start with some SVGs.</p>
                </div>
                
                <div className="team-member">
                    <div><img src="/img/lprofessor.gif"></img></div>
                    <p><a href="https://twitter.com/L_ProfessorGR" target="_blank">L Professor</a> is an accomplished music producer from Athens, Greece. His work as a sound engineer has been certified Platinum & Gold, and has produced, recorded & mixed tracks for some of the top artists in the Greek music scene.</p>
                </div>
            </section>
        </div>
        
    )
}