import '../css/track.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';
import { useState } from 'react';
import { createColorPickers, createStorePaletteButton, holdsNFT, toggleNoDistractionsMode } from '../helpers';

export default function Track4({address,isConnected,isDisconnected}) {
    const [colorPalette,setColorPalette] = useState(["#946aa0","#AAFF00","#FFAA00","#FF00AA"])

    let fft, analyzer, level, audio, toggleBtn, uploadBtn, uploadedAudio, uploadAnim, storePalette;
    let uploadLoading = false;


    const preload = (p5) => {
        audio = p5.loadSound("/audio/4.mp3");
    }

    const uploadedAudioPlay = (audioFile) => {

        uploadLoading = false;

        if (audio.isPlaying()) {
            audio.pause();
        }

        audio = audioFile;
        audio.loop();
    }


    const setup = (p5, canvasParentRef) => {
        console.log(isConnected,address)
        uploadAnim = p5.select('#uploading-animation');

        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        toggleBtn = p5.createButton("Play / Pause");

        
        if(holdsNFT(4, address)){
            // color pickers
            createColorPickers(p5,colorPalette,colorChanged);
            
            // upload track
            uploadBtn = p5.createFileInput((file) => {
                uploadLoading = true;
                uploadedAudio = p5.loadSound(file.data, uploadedAudioPlay);
                }
            );
    
            uploadBtn.addClass("upload-btn");
            
            // store color palette
            createStorePaletteButton(p5,colorPalette);
        }
        

        toggleBtn.addClass("toggle-btn");
        
        toggleBtn.mousePressed(toggleAudio);


        analyzer = new window.p5.Amplitude();
        fft = new window.p5.FFT();

        audio.loop();

    }

    const draw = (p5) => {

        if (uploadLoading) {
            uploadAnim.addClass('is-visible');
        } else {
            uploadAnim.removeClass('is-visible');
        }

        p5.background(colorPalette[0]);
        p5.translate(p5.windowWidth / 2, p5.windowHeight / 2);

        level = analyzer.getLevel();
        fft.analyze();

        const bass = fft.getEnergy("bass");
        const treble = fft.getEnergy(2, 250);
        const mid = fft.getEnergy(1, 250);

        if (!audio.isPlaying()) {
            const mapBassX = p5.map(p5.mouseX, 0, p5.width, 400, 1200);

            for (let b = 0; b < 70; b++) {

                p5.push();
                p5.noFill();
                p5.stroke(colorPalette[1]);
                p5.rotate(b);
                // let mapScale = p5.map(b, 0, 100, 0, 3);
                p5.strokeWeight(1);
                p5.bezier(mapBassX - b, 20, 10, 20, 100, 50, p5.mouseY, p5.mouseY);
                p5.pop();

            }
        } else {
            
            /*----------  BASS  ----------*/
            let _mapBassX = p5.map(p5.mouseX, 0, p5.width, 400, 1200);
            for (let b = 0; b < bass; b++) {
                let _mapScale = p5.map(b, 0, bass, 0, 3);
                p5.push();
                p5.noFill();
                p5.stroke(colorPalette[1]);
                p5.rotate(b * p5.frameCount);
                p5.strokeWeight(_mapScale);
                p5.bezier(_mapBassX - b, 20, 10, 20, 100, 50, p5.mouseY, p5.mouseY);
                p5.pop();
            }


            /*----------  MID  ----------*/
            for (let m = 0; m < mid; m += 20) {

                var angle = m * 3 * p5.random();
                p5.strokeWeight(1);
                p5.push();

                p5.fill(p5.random(100, 255), p5.random(100, 255), p5.random(100, 255), p5.random(0, 255));
                p5.fill(colorPalette[2]);
                p5.rotate(angle * 5);
                p5.scale(level / 2);

                p5.rect(p5.mouseX + m * 10, p5.mouseY + m * 50, m * 7, m * 7);
                
                p5.pop();

            }


            /*----------  TREMBLE  ----------*/
            for (let j = 5; j < treble; j += 20) {

                let angleT = j * 3 * p5.random();
                p5.strokeWeight(1);
                p5.push();
                p5.fill(colorPalette[3]);
                p5.rotate(angleT * 5);
                p5.scale(level / 4);

                p5.rect(p5.mouseX * j + 10, p5.mouseY * j, 200 * j, j * 5);
                p5.pop();

            }

        }

    }

    const colorChanged = (index,color) => {
        let temp = colorPalette;
        temp[index] = color;
        setColorPalette(temp);
    }
    const toggleAudio = () => {
        if (audio.isPlaying()) {
            audio.pause();
        } else {
            audio.play();
        }
    }


    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    useEffect(() => {
         document.addEventListener('keydown', toggleNoDistractionsMode);

        return () => {
            document.removeEventListener('keydown', toggleNoDistractionsMode);
            if(audio !== undefined)audio.disconnect()
        }
    }, [])

    return (
		<div className="content main">
            <div className="canvas-wrapper">
                <div id="p5_loading" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <div id="uploading-animation" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
            </div>
        </div>
    );
}