import { Routes, Route, Link , useLocation } from 'react-router-dom';
import About from './components/About';
import Home from './components/Home';
import Mint from './components/Mint';
import Track1 from './components/Track1';
import Track2 from './components/Track2';
import Track3 from './components/Track3';
import Track4 from './components/Track4';
import Track5 from './components/Track5';
import Track6 from './components/Track6';
import Track7 from './components/Track7';
import Track8 from './components/Track8';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useConnect } from 'wagmi';
import Gallery from './components/Gallery';


function App() {
  
  const { address, isConnected, isDisconnected } = useAccount()
  const location = useLocation();
  const connect = useConnect({
    onSuccess(data) {
      console.log('Connect', data)
    },
  })

  return (
      <main>
        <div className="nav">
          <Link to="/"><h1 className="logo">Synesthesia[intro]</h1></Link>
          <div className="menu">
            <Link to="/about">About</Link>
            <Link to="/mint">Mint</Link>
            <Link to="/gallery">Gallery</Link>
            <a href="https://twitter.com/xSYNESTHESIAx" target='_blank'>Twitter</a>
          </div>
          <div className="tracks">
            <a href="#">TRACKS</a>
            <Link to="/track/1">1</Link>
            <Link to="/track/2">2</Link>
            <Link to="/track/3">3</Link>
            <Link to="/track/4">4</Link>
            <Link to="/track/5">5</Link>
            <Link to="/track/6">6</Link>
            <Link to="/track/7">7</Link>
            <Link to="/track/8">8</Link>
          </div>
          {location?.pathname == '/' && (
          <button className="toggle toggle--on button" id="toggle-btn">
            <svg className="toggle__icon toggle__icon--on" viewBox="0 0 32 31">
              <title>Sound on</title>
              <path viewBox="0 0 32 31" d="M6.405 9.239H0v13.128h6.405l12.474 8.316V.923L6.405 9.239zM5.75 20.491H1.875v-9.377h3.876v9.377zm11.252 6.687l-9.376-6.25v-10.25l9.376-6.25v22.75zm10.884-21.32c5.484 5.483 5.484 14.406 0 19.89l-1.326-1.326c4.753-4.752 4.753-12.486 0-17.239l1.326-1.326zm-2.652 2.651c4.021 4.022 4.021 10.566 0 14.587l-1.326-1.326c3.29-3.29 3.29-8.644 0-11.935l1.326-1.326zm-2.652 2.652a6.571 6.571 0 010 9.283l-1.327-1.326a4.694 4.694 0 000-6.63l1.327-1.327z"/>
            </svg>
            <svg className="toggle__icon toggle__icon--off" viewBox="0 0 32 31">
              <title>Sound off</title>
              <path d="M6.409 9.11H0v13.135h6.409l12.48 8.32V.79L6.41 9.109zm-.655 11.258H1.876v-9.382h3.878v9.382zm11.26 6.691L7.63 20.804V10.55l9.382-6.255V27.06zM32 11.65l-1.327-1.326-4.027 4.027-4.028-4.027-1.327 1.326 4.028 4.028-4.028 4.028 1.327 1.327 4.027-4.028 4.028 4.028L32 19.705l-4.028-4.028L32 11.65z"/>
            </svg>
          </button>
          )}
          <div id="web3-button-wrapper">
            <ConnectButton accountStatus="address" chainStatus="icon" showBalance={false}/>
          </div>
        </div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="about" element={<About />}></Route>
            <Route path="mint" element={<Mint />}></Route>
            <Route path="gallery" element={<Gallery />}></Route>

            <Route path="track/1" element={<Track1 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/2" element={<Track2 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/3" element={<Track3 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/4" element={<Track4 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/5" element={<Track5 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/6" element={<Track6 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/7" element={<Track7 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
            <Route path="track/8" element={<Track8 address={address} isConnected={isConnected} isDisconnected={isDisconnected} />}></Route>
          </Routes>
    </main>
  );
}

export default App;
