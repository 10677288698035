import '../css/track.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';
import { useState } from 'react';
import { createColorPickers, createStorePaletteButton, holdsNFT, toggleNoDistractionsMode } from '../helpers';

export default function Track5({address,isConnected,isDisconnected}) {
    
    let pieces, radius, fft, analyzer, level, mapMouseX, mapMouseY, mapMouseXbass, audio, toggleBtn, uploadBtn, uploadedAudio, uploadAnim;
    const [colorPalette,setColorPalette] = useState(["#02073c", "#5b0ff5", "#f50fac", "#d581b6"]);
    let uploadLoading = false;


    const preload = (p5) => {
        audio = p5.loadSound("/audio/5.mp3");
    }

    const uploadedAudioPlay = (audioFile) => {

        uploadLoading = false;

        if (audio.isPlaying()) {
            audio.pause();
        }

        audio = audioFile;
        audio.loop();
    }


    const setup = (p5, canvasParentRef) => {
        uploadAnim = p5.select('#uploading-animation');

        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        toggleBtn = p5.createButton("Play / Pause");

        
        if(holdsNFT(4, address)){
            // color pickers
            createColorPickers(p5,colorPalette,colorChanged);
            
            // upload track
            uploadBtn = p5.createFileInput((file) => {
                uploadLoading = true;
                uploadedAudio = p5.loadSound(file.data, uploadedAudioPlay);
                }
            );
    
            uploadBtn.addClass("upload-btn");
            
            // store color palette
            createStorePaletteButton(p5,colorPalette);
        }

        toggleBtn.addClass("toggle-btn");
        
        toggleBtn.mousePressed(toggleAudio);


        analyzer = new window.p5.Amplitude();
        fft = new window.p5.FFT();

        audio.loop();

    }

    const draw = (p5) => {

        const polygon = (x, y, radius, npoints) => {
            const angle = p5.TWO_PI / npoints;
            p5.beginShape();
            for (let a = 0; a < p5.TWO_PI; a += angle) {
                let sx = x + p5.cos(a) * radius;
                let sy = y + p5.sin(a) * radius;
                p5.vertex(sx, sy);
            }
            p5.endShape(p5.CLOSE);
        }

        if (uploadLoading) {
            uploadAnim.addClass('is-visible');
        } else {
            uploadAnim.removeClass('is-visible');
        }

        p5.background(colorPalette[0]);

        p5.translate(p5.windowWidth / 2, p5.windowHeight / 2);

        level = analyzer.getLevel();
        fft.analyze();

        const bass = fft.getEnergy(100, 150);
        const treble = fft.getEnergy(150, 250);
        const mid = fft.getEnergy("mid");

        const mapMid = p5.map(mid, 0, 255, -100, 200);
        const scaleMid = p5.map(mid, 0, 255, 1, 1.5);

        const mapTreble = p5.map(treble, 0, 255, 200, 350);
        const scaleTreble = p5.map(treble, 0, 255, 0, 1);

        const mapbass = p5.map(bass, 0, 255, 50, 200);
        const scalebass = p5.map(bass, 0, 255, 0.05, 1.2);

        mapMouseX = p5.map(p5.mouseX, 0, p5.width, 1, 50);
        mapMouseXbass = p5.map(p5.mouseX, 0, p5.width, 1, 5);
        mapMouseY = p5.map(p5.mouseY, 0, p5.height, 2, 6);

        pieces = 20;
        radius = 100;

        for (let i = 0; i < pieces; i += 0.1) {

            p5.rotate(p5.TWO_PI / (pieces / 2));

            p5.noFill();

            /*----------  BASS  ----------*/
            p5.push();
            p5.stroke(colorPalette[1]);
            p5.rotate(p5.frameCount * 0.002);
            p5.strokeWeight(0.5);
            polygon(mapbass + i, mapbass - i, mapMouseXbass * i, 3);
            p5.pop();


            /*----------  MID  ----------*/
            p5.push();
            p5.stroke(colorPalette[2]);
            p5.strokeWeight(0.2);
            polygon(mapMid + i / 2, mapMid - i * 2, mapMouseX * i, 7);
            p5.pop();


            /*----------  TREMBLE  ----------*/
            p5.push();
            p5.stroke(colorPalette[3]);
            p5.strokeWeight(0.6);
            p5.scale(p5.mouseX * 0.0005);
            p5.rotate((p5.mouseX * 0.002));
            polygon(mapTreble + i / 2, mapTreble - i / 2, mapMouseY * i / 2, 3);
            p5.pop();

        }

    }

    const colorChanged = (index,color) => {
        let temp = colorPalette;
        temp[index] = color;
        setColorPalette(temp);
    }

    const toggleAudio = () => {
        if (audio.isPlaying()) {
            audio.pause();
        } else {
            audio.play();
        }
    }


    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    useEffect(() => {
         document.addEventListener('keydown', toggleNoDistractionsMode);

        return () => {
            document.removeEventListener('keydown', toggleNoDistractionsMode);
            if(audio !== undefined)audio.disconnect()
        }
    }, [])

    return (
		<div className="content main">
            <div className="canvas-wrapper">
                <div id="p5_loading" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <div id="uploading-animation" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                
                <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
            </div>
        </div>
    );
}