export const createColorPickers = (p5,palette,colorChanged) => {
    for(let i=0;i<palette.length;i++){
        let colorPicker = p5.createColorPicker(palette[i]);
        colorPicker.addClass('color-control color-'+i)
        colorPicker.elt.addEventListener('change',(e) => {
            console.log(e.target.value);
            colorChanged(i,e.target.value)
        })
    }
}

export const createStorePaletteButton = (p5,palette) => {
    let storePalette = p5.createButton("Save");
    storePalette.addClass("store-palette");
    storePalette.elt.addEventListener('click',(e) => {
        console.log(palette);
    })
}

export const holdsNFT = (trackId,address) => {
    //TODO: call contract to check if address owns current track
    if(address != undefined) return true;
    return false;
}

export const storeColorPalette = (track,colors) => {

}

export const toggleNoDistractionsMode = (event) => {
    if(event.keycode == 75 || event.key == 'k'){
        let val = (document.getElementsByClassName('nav')[0].style.visibility == 'hidden') ? 'unset' : 'hidden';
        document.getElementsByClassName('nav')[0].style.visibility = val;
        document.getElementsByClassName('toggle-btn')[0].style.visibility = val;
        document.getElementsByClassName('upload-btn')[0].style.visibility = val;
        document.getElementsByClassName('store-palette')[0].style.visibility = val;
        document.getElementsByClassName('color-control').forEach(picker => {
            picker.style.visibility = val;
        })
    }    
}
