import '../css/home.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';

export default function Home() {
    let demo2Shader, img, d_map, fft, audio, toggleBtn

  const preload = (p5) => {
    audio         = p5.loadSound('/audio/home.mp3')
    demo2Shader   = p5.loadShader('/shaders/base.vert', '/shaders/d2.frag')
    img           = p5.loadImage('/img/bg2.png')
    d_map         = p5.loadImage('/img/clouds.jpg')
  }

  const setup = (p5, canvasParentRef) => {
    let playBtn = document.querySelector('#play-btn')
    playBtn.addEventListener('click', () => {
      document.body.classList.add('start-anim')
      audio.loop()
    })

    p5.pixelDensity(1)
    p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL)

    toggleBtn = document.querySelector('#toggle-btn')
    toggleBtn.addEventListener('click', () => {
      toggleBtn.classList.toggle('toggle--on')
      toggleAudio()
    })

    fft = new window.p5.FFT()
    p5.shader(demo2Shader)
    demo2Shader.setUniform('u_resolution', [p5.windowWidth, p5.windowHeight])
    demo2Shader.setUniform('d_map', d_map)
    demo2Shader.setUniform('img', img)
    demo2Shader.setUniform('texRes', [img.width, img.height])
  }

    
    
	const draw = (p5) => {
    p5.background(0)

    fft.analyze()

    const bass    = fft.getEnergy("bass")
    const treble  = fft.getEnergy("treble")
    const mid     = fft.getEnergy("highMid")
    const lowMid  = fft.getEnergy("mid")

    const mapBass = p5.map(bass, 0, 255, 0.0, 0.04)
    const mapMid  = p5.map(mid, 0, 30, 0.0, 0.8)
    const mapLowMid  = p5.map(lowMid, 0, 60, 0.0, 0.4)

    const tc = p5.map(audio.currentTime(), 0, audio.duration(), 1.0, 1.0)
    demo2Shader.setUniform('u_time', tc)
    demo2Shader.setUniform('u_bass', mapBass)
    demo2Shader.setUniform('u_mid', mapMid)
    demo2Shader.setUniform('u_lowmid', mapLowMid)

    p5.rect(0, 0 , p5.width, p5.height)
  }
  
  const windowResized = (p5) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
    demo2Shader.setUniform('u_resolution', [p5.windowWidth, p5.windowHeight])
  }

  const toggleAudio = () => {
    if (audio.isPlaying()) {
      audio.pause()
    } else {
      audio.loop()
    }
  }

    useEffect(() => {
        
        return () => {
            if(audio !== undefined)audio.disconnect()
            document.body.classList.remove('start-anim')
        }
    }, [])

    return (
    <div>
        <div className="content">
        <h3 className="content__subtitle">L Professor <span className="lowercase">x</span> 0<span className="lowercase">x</span>Acid</h3>
        <h2 className="content__title">synesthesia</h2>
        <div className="content__credits">
          Synesthesia <small>is when you hear</small>music<small>, but you see </small>shapes. <br></br>
          <small>Or you</small> hear a word<small> or a name and instantly </small>see a color.<br></br>
          Synesthesia <small>is a fancy name for when you experience one of your </small>senses<small> through another.</small>
          
        <br /><br /><a href="https://nftartgreece.com" target="_blank" rel="sponsored">Powered by NFT Art Greece</a>
            </div>
        </div>
        <div className="screen screen--grid intro">
        <p className="intro__content">
            <span className="intro__content-line">0xACID</span>
            <span className="intro__content-line intro__content-line--small">visuals & development</span>
        </p>
        <p className="intro__content">
            <span className="intro__content-line intro__content-line--small">beats by</span>
            <span className="intro__content-line">L PROFESSOR</span>
        </p>
        <p className="intro__content">
            <span className="intro__content-line intro__content-line--small">in association with</span>
            <span className="intro__content-line">NFT ART GREECE</span>
        </p>
        </div>
        <div className="screen screen--flex action">
        <h3 className="content__title">synesthesia</h3>
        <span className="action__line">Turn your sound on</span>
        <button id="play-btn" className="button button--box">Enter</button>
        </div>
        <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
        <div id="p5_loading" className="p5_loading">
          <div className="p5_loading__inner"></div>
        </div>
    </div>
    );
}