import '../css/track.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';
import { useState } from 'react';
import { createColorPickers, createStorePaletteButton, holdsNFT, toggleNoDistractionsMode } from '../helpers';

export default function Track6({address,isConnected,isDisconnected}) {
    
    let spaceBetweenLines, fft, spectrum, audio, toggleBtn, uploadBtn, uploadedAudio, uploadAnim;
    const [colorPalette,setColorPalette] = useState(["#000"]);
    let uploadLoading = false;


    const preload = (p5) => {
        audio = p5.loadSound("/audio/6.mp3");
    }

    const uploadedAudioPlay = (audioFile) => {
        uploadLoading = false;

        if (audio.isPlaying()) {
            audio.pause();
        }

        audio = audioFile;
        audio.loop();
    }


    const setup = (p5, canvasParentRef) => {
        uploadAnim = p5.select('#uploading-animation');

        p5.createCanvas(p5.windowWidth, p5.windowHeight);
        p5.angleMode(p5.DEGREES); 
        p5.colorMode(p5.HSB);
        
        toggleBtn = p5.createButton("Play / Pause");

        
        if(holdsNFT(4, address)){
            // color pickers
            createColorPickers(p5,colorPalette,colorChanged);
            
            // upload track
            uploadBtn = p5.createFileInput((file) => {
                uploadLoading = true;
                uploadedAudio = p5.loadSound(file.data, uploadedAudioPlay);
                }
            );
    
            uploadBtn.addClass("upload-btn");
            
            // store color palette
            createStorePaletteButton(p5,colorPalette);
        }

        toggleBtn.addClass("toggle-btn");
        
        toggleBtn.mousePressed(toggleAudio);


        // fft = new window.p5.FFT(0.9,128);
        fft = new window.p5.FFT(0.9,64);
        spaceBetweenLines = p5.width/128
        audio.loop();

    }

    const draw = (p5) => {

        if (uploadLoading) {
            uploadAnim.addClass('is-visible');
        } else {
            uploadAnim.removeClass('is-visible');
        }

        p5.background(colorPalette[0]);

        let spectrum = fft.analyze();
        for (let i = 0; i < spectrum.length; i++) {
            // p5.fill(i,255,255);
            // let amp = spectrum[i];
            // let y = p5.map(amp, 0, 256, p5.height, 0);
            // p5.rect(i * spaceBetweenLines, y, spaceBetweenLines, p5.height - y);
            
            p5.fill(((i*10 * (p5.mouseX / p5.width)) + 360*(p5.mouseY / p5.height))%360,255,255);
            // Math.abs(p5.mouseX - p5.width/2)
            // Math.abs(p5.mouseY - p5.height/2)
            let amp = spectrum[i];
            let y = p5.map(amp, 0, 256, p5.height, 350);
            p5.rect((p5.width/2) + (i * spaceBetweenLines), y, spaceBetweenLines, p5.height - y);
            p5.rect((p5.width/2) - (i * spaceBetweenLines), y, spaceBetweenLines, p5.height - y);
        }

    }

    const colorChanged = (index,color) => {
        let temp = colorPalette;
        temp[index] = color;
        setColorPalette(temp);
    }

    const toggleAudio = () => {
        if (audio.isPlaying()) {
            audio.pause();
        } else {
            audio.play();
        }
    }


    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    useEffect(() => {
         document.addEventListener('keydown', toggleNoDistractionsMode);

        return () => {
            document.removeEventListener('keydown', toggleNoDistractionsMode);
            if(audio !== undefined)audio.disconnect()
        }
    }, [])

    return (
		<div className="content main">
            <div className="canvas-wrapper">
                <div id="p5_loading" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <div id="uploading-animation" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                
                <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
            </div>
        </div>
    );
}