import Countdown from "react-countdown";
import AVAX from "./AVAX";
import { useState } from "react";

export default function Mint() {
    const [mintAmount,setMintAmount] = useState(1);

    const mintAmountChanged = (e) => {
        setMintAmount(e.target.value)
    }

    const mint = () => {
        // TODO: call contract
        console.log('minting '+mintAmount);
    }

    return (
        <div className="container mint">
            
            <div className="mint-preview">
                <img src="/img/question-mark.gif"></img>
                <p>PUBLIC SALE: 3 <AVAX/></p>
            </div>
            {Date.now() > 1687104000000 ? (
                <div className="mint-controls">
                    <label>AMOUNT
                        <input type="number" value={mintAmount} onChange={mintAmountChanged} step="1" min="1" max="8"></input>
                    </label>
                    <p><button className="button" onClick={mint}>MINT</button></p>
                </div>
            ):(
                <div style={{textAlign: 'center'}}>
                    <p>TIME TO MINT</p>
                    <Countdown date={1687104000000} />
                </div>
            )}
            
        </div>
    )
}