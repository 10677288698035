import '../css/track.css';
import Sketch from 'react-p5';
import 'p5/lib/addons/p5.sound';
import { useEffect } from 'react';
import { useState } from 'react';
import { createColorPickers, createStorePaletteButton, holdsNFT, toggleNoDistractionsMode } from '../helpers';

export default function Track8({address,isConnected,isDisconnected}) {
    // http://mathworld.wolfram.com/LissajousCurve.html
    let pieces, radius, fft, mapMouseX, mapMouseY, audio, toggleBtn, uploadBtn, uploadedAudio, uploadAnim;
    const [colorPalette,setColorPalette] = useState(["#000", "#fff", "#00a6e0"]);
    let uploadLoading = false;
    let counter = 0;


    const preload = (p5) => {
        audio = p5.loadSound("/audio/8.mp3");
    }

    const uploadedAudioPlay = (audioFile) => {

        uploadLoading = false;

        if (audio.isPlaying()) {
            audio.pause();
        }

        audio = audioFile;
        audio.loop();
    }


    const setup = (p5, canvasParentRef) => {
        uploadAnim = p5.select('#uploading-animation');

        p5.createCanvas(p5.windowWidth, p5.windowHeight);

        toggleBtn = p5.createButton("Play / Pause");

        if(holdsNFT(4, address)){
            // color pickers
            createColorPickers(p5,colorPalette,colorChanged);
            
            // upload track
            uploadBtn = p5.createFileInput((file) => {
                uploadLoading = true;
                uploadedAudio = p5.loadSound(file.data, uploadedAudioPlay);
                }
            );
    
            uploadBtn.addClass("upload-btn");
            
            // store color palette
            createStorePaletteButton(p5,colorPalette);
        }

        toggleBtn.addClass("toggle-btn");
        
        toggleBtn.mousePressed(toggleAudio);


        fft = new window.p5.FFT();

        audio.loop();

    }

    const draw = (p5) => {

        if (uploadLoading) {
            uploadAnim.addClass('is-visible');
        } else {
            uploadAnim.removeClass('is-visible');
        }

        p5.background(colorPalette[0]);

        p5.translate(p5.width/2, p5.height/2);

        mapMouseX = p5.map(p5.mouseX, 0, p5.width, 4, 10);
        mapMouseY = p5.map(p5.mouseY, 0, p5.height, p5.windowHeight / 4, p5.windowHeight);
        let spectrum = fft.analyze();

        const bass = fft.getEnergy("bass");
        const treble = fft.getEnergy("treble");
        const mid = fft.getEnergy("mid");

        for (let i =0; i<360; i++) {
            let x = 400*p5.cos(mapMouseX*spectrum[i]);
            let y = 200*p5.sin(mapMouseY*spectrum[i]);

            const sc = (bass + mid + treble ) / 255
            p5.strokeWeight(4);
            if(bass + mid + treble > 360){
                p5.stroke(colorPalette[2])
                // if(i < 120){
                //     p5.stroke(bass,255,255);    
                // }
                // if(i >= 120 && i < 240){
                //     p5.stroke(255,mid,255);    
                // }
                // if(i >= 240){
                //     p5.stroke(255,255,treble);    
                // }
                p5.point(x*sc, y*sc);    
            }else{
                p5.stroke(255)
                p5.stroke(colorPalette[1])
                p5.point(x * sc, y * sc);
            }
            
        }

    }


    const colorChanged = (index,color) => {
        let temp = colorPalette;
        temp[index] = color;
        setColorPalette(temp);
    }
    const toggleAudio = () => {
        if (audio.isPlaying()) {
            audio.pause();
        } else {
            audio.play();
        }
    }


    const windowResized = (p5) => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

    useEffect(() => {
         document.addEventListener('keydown', toggleNoDistractionsMode);

        return () => {
            document.removeEventListener('keydown', toggleNoDistractionsMode);
            if(audio !== undefined)audio.disconnect()
        }
    }, [])

    return (
		<div className="content main">
            <div className="canvas-wrapper">
                <div id="p5_loading" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                <div id="uploading-animation" className="p5_loading">
                    <div className="p5_loading__inner">
                        <span></span>
                    </div>
                </div>
                
                <Sketch preload={preload} setup={setup} draw={draw} windowResized={windowResized} />
            </div>
        </div>
    );
}